@tailwind base;
@tailwind components;
@tailwind utilities;


html {
	font-family: 'Poppins', sans-serif;
	-webkit-tap-highlight-color: transparent;
	height: 100%;

}

body {
	@apply text-white text-md xl:text-lg;
  height: 100%;
  width:100%;
 
}

img {
	max-width: none;
}

.container {
	@apply px-4;
}

/* .container-for-abstract {
    width: 100%;
    overflow: hidden;
	position: relative;
	
    position: relative; Needed for absolute positioning of abstract-img
} */

.abstract-img {
    @apply w-[800px] left-[10%] lg:left-[40%];
    position: absolute;
	top:0;
    transform: translateX(-50%);
    /* ... (other styles) */
    transform-style: preserve-3d;
    will-change: transform;
    /*z-index: 99; /* Add a negative z-index to place it behind the text */
}


  


.btn {
  @apply bg-third rounded-full font-bold px-6 py-2 hover:scale-110 transition-all;
}

.nav-active {
  @apply text-third transition-all;
}

.fade-down{
  background: rgb(28,28,33);
background: linear-gradient(360deg, rgba(28,28,33,1) 0%, rgba(15,15,18,1) 100%);

}

.fade-up{
  background: rgb(28,28,33);
background: linear-gradient(180deg, rgba(28,28,33,1) 0%, rgba(15,15,18,1) 100%);
}

.fade-up-bg{
  background: rgb(28,28,33);
  background: linear-gradient(180deg, rgba(15,15,18,0) 0%, rgba(15,15,18,1) 100%);
}

.fade-down-bg{
  background: rgb(28,28,33);
  background: linear-gradient(360deg, rgba(15,15,18,0) 0%, rgba(15,15,18,1) 100%);
}

.collapsible {
	@apply w-full border-2 border-light-gray rounded-lg my-3 overflow-hidden text-white;
}

.collapsible__header {
	@apply flex bg-light-gray px-4 pt-4 pb-3 justify-between items-center cursor-pointer select-none;
}

.collapsible__body {
	max-height: 0px;
	transition: max-height 0.2s;
	position: relative;
}


#user-dropdown,
#nav-mobile-menu {
	@apply w-56 bg-primary shadow-lg;
}

#user-dropdown .user-item {
	@apply flex p-3 rounded-md hover:bg-light-gray cursor-pointer;
}

.login-container {
	@apply w-full bg-secondary rounded-xl max-w-[370px];
	max-height: 100vh;
	overflow-y: auto;
}

.login-banner,
.register-banner {
	@apply rounded-t-xl;
	overflow: hidden;
	margin-top: -30px;
}

@media screen and (max-width: 390px) {
	.login-container {
		max-width: 340px;
	}
}

@media screen and (max-height: 745px) {
	#login-panel.register-page .login-container {
		margin: 0 auto;
		max-width: 370px;
		border-radius: 0;
		min-height: 100%;
	}

	.register-banner {
		border-radius: 0 !important;
	}
}

@media screen and (max-height: 620px) {
	#login-panel.login-page .login-container {
		margin: 0 auto;
		max-width: 370px;
		border-radius: 0;
		min-height: 100%;
	}

	.login-banner {
		border-radius: 0 !important;
	}
}

#root {
	height: 100%;
}

#modal-root {
	position: relative;
	min-width: 0;
	min-height: 0;
}

#view {
	min-height: 100%;
}


/* New modal */
.modal-container {
	@apply fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center overflow-auto;
	background-color: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(5px);
	animation: fade-in 0.2s;
	transition: opacity 0.2s;
	z-index: 99;
	will-change: transform;
}

.modal-container.exiting {
	opacity: 0;
}

.modal-container.exiting .modal-body {
	transform: scale(0.8);
	opacity: 0;
}

.modal-body {
	@apply shrink-0;
	box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
	transition: transform 0.2s, opacity 0.2s;
	animation: modal-in 0.2s;
	will-change: transform;
	max-height: 100vh;
	overflow-y: auto;
}

.modal-close-button {
	@apply absolute top-2 right-2 p-2.5 rounded-full cursor-pointer text-white;
	transition: transform 0.2s;
}

.modal-close-button.small {
	@apply p-2;
}

.modal-close-button:hover {
	transform: scale(1.1);
}

.modal-close-button.dark {
	background-color: rgba(0, 0, 0, 0.25);
}

.modal-close-button.light {
	background-color: rgba(255, 255, 255, 0.12);
}

.modal-close-button.gray {
	@apply !text-[#888] bg-gray;
}

@keyframes modal-in {
	0% {
		opacity: 0;
		transform: scale(0.8);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.modal-image-preview {
	@apply rounded-lg;
	min-width: 150px;
	max-width: calc(100vw - 12px);
	max-height: calc(100vh - 12px);
}

.image-picker-preview-modal {
	@apply bg-transparent;
	width: 85vmin;
	max-width: 600px;
}

.image-picker-preview-modal img {
	@apply rounded-md object-cover w-full h-full;
	max-height: calc(100vh - 100px);
}

.image-picker-preview-modal img.contain {
	@apply !object-contain;
}


.primary-light {
  @apply bg-light-blue text-blue;
  transition: background-color 0.2s, color 0.2s;
}
.primary-light:hover {
  @apply bg-light-blue-intense;
}

/* For FireFox */
.custom-scroll {
  scrollbar-color: #ddd #fff;
}

/* Works on Chrome, Edge, and Safari */
.custom-scroll::-webkit-scrollbar {
  width: 13px;
}

.custom-scroll::-webkit-scrollbar-track {
  @apply rounded-xl;
  background: #fff;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 20px;
  border: 3px solid #fff;
}

/* Popover */
.popover-parent {
	@apply relative cursor-pointer select-none;
}

.popover {
	@apply absolute bg-white rounded-lg z-40 p-2 opacity-0 cursor-default;
	visibility: hidden;
	transform: translateY(20px);
	transition: opacity 0.2s, transform 0.2s;
	top: calc(100% + 8px);
	box-shadow: 0 4px 15px rgb(0 0 0 / 8%);
	will-change: transform, opacity;
}

.select-popover {
	@apply right-0 custom-scroll min-w-full overflow-x-hidden text-primary;
	max-height: 300px;
	overflow-y: auto;
}

.popover.open {
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
}

.popover.hiding {
	opacity: 0;
	transform: translateY(20px);
}

.popover .select-option {
	@apply px-4 py-2 cursor-pointer hover:bg-light-gray rounded-md overflow-hidden overflow-ellipsis whitespace-nowrap text-center;
}

.popover .select-option.selected {
	@apply primary-light;
}


.video-js{
	@apply w-full h-[230px] md:h-[360px] xl:h-[640px];
}

.text-content {
	position: relative;
	z-index: 1; /* Set a higher z-index */
  }
  

  /* Add this to your CSS */
@keyframes rotate {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  
  .rotate-initially {
	animation: rotate 100s linear infinite; /* Adjust the duration as needed */
  }

  
#side-menu {
	@apply absolute top-0 right-0 bottom-0 p-4 rounded-none overflow-hidden overflow-y-auto text-white bg-primary;
	width: 100%;
	max-width: 300px;
	transition: transform 0.3s;
	will-change: transform;
	animation: slide-in-right 0.2s !important;
}

@keyframes slide-in-right {
	0% {
		opacity: 0;
		transform: translateX(+50%);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@property --angle {
	syntax: '<angle>';
	initial-value: 90deg;
	inherits: true;
  }
  
  @property --gradX {
	syntax: '<percentage>';
	initial-value: 50%;
	inherits: true;
  }
  
  @property --gradY {
	syntax: '<percentage>';
	initial-value: 0%;
	inherits: true;
  }
  
  
  :root {
	  --d: 2500ms;
	  --angle: 90deg;
	  --gradX: 100%;
	  --gradY: 50%;
	  --c1: rgb(255, 255, 255);
	  --c2: rgba(168, 239, 255, 0.1);
  }
  
  .wrapper {
	  min-width: min(40rem, 100%);
  }
  
  .card {
	font-size: 3vw;
	margin: max(1rem, 3vw);
	border: 0.35rem solid;
	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.25turn, var(--c1) 0.25turn, var(--c2) 0.25turn) 30;
	animation: borderRotate var(--d) linear infinite forwards;
	/* transition: none; Disable transitions on hover */
  }
  
  .card:hover {
	/* Remove the animation on hover */
	@apply xl:scale-110 xl:transition-transform;
  }
  
  @keyframes borderRotate {
	100% {
	  --angle: 420deg;
	}
  }

  .react-calendar__tile--now{
	background-color: gray!important;
  }

  .react-date-picker__button svg {
	stroke: white;
  }

.react-date-picker__wrapper{
outline: none!important;
border:0px solid!important;
  }

  .react-date-picker__inputGroup__input:invalid{
	background-color: inherit!important;
  }

  .react-date-picker{
@apply px-4;
  }

  .text-stroke {
	color: transparent;
	-webkit-text-stroke: 2px white;
	text-stroke: 2px white;
  }


  /* CountdownTimer.css */

.timer-container {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
  }
  
  .timer-item {
	margin: 0 10px;
	text-align: center;
  }
  
  .timer-item span {
	@apply text-xl xl:text-4xl;
	display: block;
	font-weight: bold;
  }

  .swiper-pagination-bullet{
	background-color: white!important;
  }
  
  .progressVisualFull {
	@apply rounded-full;
	display: flex;
	height: 22px;
	margin: 12px 0;
  }
  
  .progressVisualPart {
	@apply rounded-full;
	transition: width 5s;
	width: 0;
	overflow: hidden;
  }
  
  .progressVisualPart.visible {
	width: 100%;
  }

 .swiper-certificates .swiper-wrapper {
	height:auto;
 }


 